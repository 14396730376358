/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useMemo } from '@web-stories-wp/react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import useStoryAnimationContext from './useStoryAnimationContext';
import { formatCssKeyframes } from '../utils/getCssAnimationFromKeyframes';

const fullSizeAbsoluteStyles = {
  width: '100%',
  height: '100%',
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
};

function ComposableWrapper({
  animationParts,
  children,
  useCssAnimations = false,
}) {
  const ComposedWrapper = useMemo(
    () =>
      animationParts.reduce(
        (Composable, animationPart) => {
          const { AMPTarget, cssAnimation, elementIdOverride } = animationPart;
          const Composed = function (props) {
            return (
              <Composable>
                {useCssAnimations && (
                  <style>
                    {formatCssKeyframes(
                      cssAnimation.animationName,
                      cssAnimation.keyframes
                    )}
                  </style>
                )}
                <AMPTarget
                  elementIdOverride={elementIdOverride}
                  cssAnimationStyle={
                    useCssAnimations
                      ? {
                          animation: cssAnimation.animation,
                          animationPlayState: 'paused',
                        }
                      : undefined
                  }
                  style={fullSizeAbsoluteStyles}
                >
                  {props.children}
                </AMPTarget>
              </Composable>
            );
          };
          Composed.propTypes = { children: PropTypes.node };
          return Composed;
        },
        (props) => props.children
      ),
    [animationParts]
  );

  return <ComposedWrapper>{children}</ComposedWrapper>;
}

ComposableWrapper.propTypes = {
  animationParts: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node.isRequired,
  useCssAnimations: PropTypes.bool,
};

function AMPWrapper({ animations = [], target, children }) {
  const {
    actions: { getAnimationParts },
    state: { useCssAnimations },
  } = useStoryAnimationContext();

  return (
    <ComposableWrapper
      animationParts={getAnimationParts(target).map((animationPart) => {
        // When we create the animations in the preview, the generated element IDs are different from
        // the ones generated in the output (since the HTML is re-generated every time we request it).
        // By override the ID of the animations element, we ensure that cssAnimations is synced with the
        // page definition.
        let elementIdOverride;
        if (animations.length > 0) {
          elementIdOverride = animations[0].elementId;
        }
        return { ...animationPart, elementIdOverride };
      })}
      useCssAnimations={useCssAnimations}
    >
      {children}
    </ComposableWrapper>
  );
}

AMPWrapper.propTypes = {
  target: PropTypes.string,
  children: PropTypes.node,
};

export default AMPWrapper;
