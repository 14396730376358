import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import VideoProps from '../propsTypes/video';
import ExternalMediaRow from './externalMediaRow';
import { useCallback, useRef, useState } from 'react';
import LoadingPill, { useShowLoadingPill } from '../../../common/loadingPill';
import MediaError from '../../common/mediaError';

const NoMediaFound = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const ExternalMediaListContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 2px;
  padding: 0px 0px 20px 12px;
`;

function ExternalMediaList({
  assets,
  isLoading,
  loadAssets,
  hasMore,
  hasError,
  enableDeletion,
  enableEditInAzzuu,
}) {
  const showLoadingPill = useShowLoadingPill(isLoading);
  const [expandedMenuId, setExpandedMenuId] = useState(null);
  const observer = useRef();
  const listContainerRef = useRef();
  const lastMediaElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadAssets({ isInitLoad: false });
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, loadAssets]
  );

  return (
    <ExternalMediaListContainer ref={listContainerRef}>
      {hasError && (
        <MediaError
          onRetry={() => loadAssets({ isInitLoad: assets.length === 0 })}
        />
      )}

      {!hasError && (
        <>
          {(!assets || assets.length == 0) && !isLoading ? (
            <NoMediaFound>No media found.</NoMediaFound>
          ) : (
            assets.map((asset, index) => {
              const isLastElement = assets.length === index + 1;

              return (
                <ExternalMediaRow
                  key={asset.id}
                  asset={asset}
                  isEditMenuExpanded={expandedMenuId === asset.id}
                  listContainerRef={listContainerRef}
                  lastElementRef={
                    isLastElement ? lastMediaElementRef : undefined
                  }
                  enableDeletion={enableDeletion}
                  enableEditInAzzuu={enableEditInAzzuu}
                  setExpandedMenuId={setExpandedMenuId}
                />
              );
            })
          )}
        </>
      )}

      {showLoadingPill && <LoadingPill />}
    </ExternalMediaListContainer>
  );
}

ExternalMediaList.propTypes = {
  video: VideoProps,
};

export default ExternalMediaList;
