/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import { rgba } from 'polished';

/**
 * Internal dependencies
 */
import { PANE_PADDING } from '../shared';

/* Undo the -1.5em set by the Pane */
export const FullWidthWrapper = styled.div`
  margin-left: -${PANE_PADDING};
  margin-right: -${PANE_PADDING};
`;

export const MediaGalleryLoadingPill = styled.div`
  display: block;
  position: absolute;
  bottom: 20px;
  left: 60px;
  right: 60px;
  text-align: center;
  padding-block: 8px;
  background-color: ${({ theme }) => rgba(theme.colors.standard.black, 0.4)};
  border-radius: 100px;
`;
