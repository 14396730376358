/**
 * External dependencies
 */
import { Page } from '@web-stories-wp/story-editor/src/elements/types';
/**
 * Internal dependencies
 */
import { isEqual } from './isEqual';

export function wasPageChanged(originalPage: Page, newPage: Page) {
  const keysToIgnore = ['isChanged'];

  return !isEqual(originalPage, newPage, keysToIgnore);
}
