import { CSSProperties } from 'react';
import type {
  CssAnimationKeyframes,
  CssAnimationWithKeyframes,
  Keyframes,
  KeyframeSteps,
  MergedCssAnimationFormattedKeyframes,
  MergedCssAnimationWithFormattedKeyframes,
  Timings,
} from './types';

const ALLOWLIST = ['opacity', 'transform'];
const PROPERTIES_ALLOWLIST = ['transform-origin'];
// In CSS, the order of the transform properties matters
const MERGED_TRANSFORM_PROPERTIES_PRIORITY = ['translate', 'rotate', 'scale'];

export function getCssAnimationFromKeyframes(
  id: string,
  keyframes: Keyframes,
  timings: Timings
): CssAnimationWithKeyframes {
  const animationName = `anim${id}`;
  let keyFramesSteps: KeyframeSteps = {};
  let styles: CSSProperties = {};

  if (Array.isArray(keyframes)) {
    const totalSteps = keyframes.length;
    const diffBetweenStops = 100 / (totalSteps - 1);

    keyframes.forEach((keyframe, index) => {
      if (typeof keyframe === 'object') {
        Object.keys(keyframe).forEach((key) => {
          const cssProperty = key.toLowerCase();

          if (ALLOWLIST.includes(cssProperty)) {
            const value = keyframe[key];
            let stop = index * diffBetweenStops;
            if ('offset' in keyframe) {
              stop = keyframe.offset * 100;
            }

            if (keyFramesSteps[stop]) {
              keyFramesSteps[stop].push({ property: cssProperty, value });
            } else {
              keyFramesSteps[stop] = [{ property: cssProperty, value }];
            }
          }
        });
      }
    });
  } else if (typeof keyframes === 'object') {
    Object.keys(keyframes).forEach((key) => {
      const cssProperty = key.toLowerCase();
      if (ALLOWLIST.includes(cssProperty)) {
        const value = keyframes[key];

        // For ALLOWLIST properties, the value can only be an array
        // eg: ['translateX(10)', 'translateX(0)']
        // eg: [0, 1]
        if (Array.isArray(value)) {
          const totalSteps = Object.keys(value).length;
          const diffBetweenStops = 100 / (totalSteps - 1);

          value.forEach((value, index) => {
            const stop = index * diffBetweenStops;

            if (keyFramesSteps[stop]) {
              keyFramesSteps[stop].push({ property: cssProperty, value });
            } else {
              keyFramesSteps[stop] = [{ property: cssProperty, value }];
            }
          });
        }
        // For PROPERTIES_ALLOWLIST properties, the value can only be a string
        // eg: 'transform-origin': 'top left'
      } else if (PROPERTIES_ALLOWLIST.includes(cssProperty)) {
        const value = keyframes[key];

        if (typeof value === 'string') {
          styles[cssProperty] = value;
        }
      }
    });
  }

  /* delay | direction | duration | fill-mode | iteration-count | name |
  play-state | timeline | timing-function */
  const animation = `${timings.direction ? `${timings.direction}` : ''}${
    timings.duration ? ` ${timings.duration}ms` : ''
  }${timings.delay ? ` ${timings.delay}ms` : ''}${
    timings.fill ? ` ${timings.fill}` : ''
  }${timings.iterations ? ` ${timings.iterations}` : ''} ${animationName}${
    timings.easing ? ` ${timings.easing}` : ''
  }`.trim();

  return {
    /**
     * The following properties are necessary to generate the right CSS
     * */
    animation,
    animationName,
    keyframes: keyFramesSteps,
    /**
     * The following properties are used by Remotion to set the animationDelay property
     * (which is dynamic and is NOT the same as the animationDelay in CSS which is only set once)
     */
    animationDurationInMs: timings.duration,
    animationDelayInMs: timings.delay || 0,
    iterations: timings.iterations || 1,
  };
}

export function formatCssKeyframes(
  animationName: string,
  keyFramesSteps: CssAnimationKeyframes | MergedCssAnimationFormattedKeyframes
) {
  if (typeof keyFramesSteps === 'object') {
    const keyframeStops = Object.keys(keyFramesSteps).sort();

    return `@keyframes ${animationName} {${keyframeStops
      .map((stop) => {
        const keyframesForStop = keyFramesSteps[stop];

        return `${stop}% {${keyframesForStop
          .map(({ property, value }) => formatCssKeyframeStep(property, value))
          .join(';')};}`;
      })
      .join('')}}`;
  }

  return keyFramesSteps;
}

function formatCssKeyframeStep(cssProperty: string, value: string) {
  return `${cssProperty}: ${value}`;
}

export function mergeCssAnimations(
  animations: CssAnimationWithKeyframes[]
): MergedCssAnimationWithFormattedKeyframes {
  // First, we check if the animations have any overlapping properties (for example,
  // if one animation sets transform: rotate and another sets transform: translate).
  // If they don't, this makes things easier as we can pass multiple animations
  // to the CSS animation property
  const allAnimationProperties = [];
  let hasDuplicateProperties = false;

  for (const animation of animations) {
    const animationProperties: string[] = [];

    Object.values(animation.keyframes).forEach((keyframe) =>
      Object.values(keyframe).forEach(({ property }) =>
        animationProperties.push(property)
      )
    );

    if (
      animationProperties.some((animationProperty) =>
        allAnimationProperties.includes(animationProperty)
      )
    ) {
      hasDuplicateProperties = true;
      break;
    }

    Array.from(new Set(animationProperties)).forEach((animationProperty) =>
      allAnimationProperties.push(animationProperty)
    );
  }

  const animationsNotRepeatingIndefinitely = animations.filter(
    ({ iterations }) => iterations !== 'infinity'
  );

  const animationsWithTotalDuration = animationsNotRepeatingIndefinitely.map(
    (animation) => ({
      ...animation,
      totalDurationInMs:
        // @ts-ignore we know that iterations is a number since we filtered out the infinitely repeating animations
        animation.animationDurationInMs * animation.iterations +
        animation.animationDelayInMs,
    })
  );

  const animationWithLongestTotalDuration = animationsWithTotalDuration.reduce(
    (prev, current) => {
      return prev && prev.totalDurationInMs > current.totalDurationInMs
        ? prev
        : current;
    }
  );

  const shortestDelay = Math.min(
    ...animations.map(({ animationDelayInMs }) => animationDelayInMs)
  );

  const totalDurationOfMergedAnimations =
    animationWithLongestTotalDuration.totalDurationInMs - shortestDelay;

  const mergedAnimationsProps = {
    // We already took iterations into account when calculating the total duration,
    // so we can reset them to the default value
    iterations: 1,
    animationDurationInMs: totalDurationOfMergedAnimations,
    animationDelayInMs: shortestDelay,
  };

  if (!hasDuplicateProperties) {
    return {
      animation: animations.map(({ animation }) => animation).join(', '),
      keyframes: animations
        .map(({ animationName, keyframes }) =>
          formatCssKeyframes(animationName, keyframes)
        )
        .join(' '),
      ...mergedAnimationsProps,
    };
  }

  const animationsRepeatingIndefinitely = animations.filter(
    ({ iterations }) => iterations === 'infinity'
  );

  if (animationsRepeatingIndefinitely.length > 1) {
    console.warn(
      'Warning: Animations that repeat indefinitely cannot be combined with other animations.'
    );
  }

  const mergedKeyframeSteps: KeyframeSteps = {};

  for (const animation of animationsNotRepeatingIndefinitely) {
    const { animationDurationInMs, animationDelayInMs, iterations, keyframes } =
      animation;
    // @ts-ignore we know that iterations is a number since we filtered out the infinitely repeating animations
    const totalDurationInMs = animationDurationInMs * iterations;
    const startingPointInMs = animationDelayInMs - shortestDelay;
    const endingPointInMs = startingPointInMs + totalDurationInMs;

    Object.keys(keyframes).forEach((stop) => {
      const newStopInMs =
        stop === '0'
          ? startingPointInMs
          : startingPointInMs + totalDurationInMs / (100 / parseFloat(stop));
      const newStop = (newStopInMs / endingPointInMs) * 100;

      keyframes[stop].forEach(({ property, value }) => {
        if (!mergedKeyframeSteps[newStop]) {
          mergedKeyframeSteps[newStop] = [{ property, value }];
        } else {
          const indexOfExistingKeyframeWithProperty = mergedKeyframeSteps[
            newStop
          ].findIndex((mergedKeyframe) => mergedKeyframe.property === property);

          if (indexOfExistingKeyframeWithProperty < 0) {
            mergedKeyframeSteps[newStop].push({ property, value });

            // We can only merge transform properties
            // eg translate and rotate
          } else if (property === 'transform') {
            const existingKeyframe =
              mergedKeyframeSteps[newStop][indexOfExistingKeyframeWithProperty];

            const priorityOfExistingValues =
              MERGED_TRANSFORM_PROPERTIES_PRIORITY.findIndex(
                (transformProperty) =>
                  `${existingKeyframe.value}`.includes(transformProperty)
              );

            const priorityOfValueToAdd =
              MERGED_TRANSFORM_PROPERTIES_PRIORITY.findIndex(
                (transformProperty) => `${value}`.includes(transformProperty)
              );

            mergedKeyframeSteps[newStop][indexOfExistingKeyframeWithProperty] =
              {
                ...existingKeyframe,
                value:
                  priorityOfValueToAdd < priorityOfExistingValues
                    ? `${value} ${existingKeyframe.value}`
                    : `${existingKeyframe.value} ${value}`,
              };
          }
        }
      });
    });
  }

  const animationName = animations[0].animationName;
  const animation = `${totalDurationOfMergedAnimations}ms ${shortestDelay}ms both ${animationName}`;

  return {
    animation,
    keyframes: formatCssKeyframes(animationName, mergedKeyframeSteps),
    ...mergedAnimationsProps,
  };
}
