type RemotionAnimation = {
  elementId: string;
  animationDuration: number;
  animationDelay: number;
  target: string;
};

type UpdateRemotionAnimationsProps = {
  remotionAnimations: RemotionAnimation[];
};

/**
 * Update the story animation state to play, pause, scrub or reset
 * active pages animations.
 *
 */
export function updateRemotionAnimations(
  state,
  { remotionAnimations }: UpdateRemotionAnimationsProps
) {
  const pageIndex = state.pages.findIndex(({ id }) => id === state.current);

  if (pageIndex === -1) {
    return state;
  }

  const page = state.pages[pageIndex];
  const pageElements = page.elements || [];
  const remotionAnimationsForPage = remotionAnimations.filter(({ target }) =>
    pageElements.some(({ id }) => id === target)
  );

  const newPage = {
    ...page,
    cssAnimations: remotionAnimationsForPage,
  };

  return {
    ...state,
    pages: [
      ...state.pages.slice(0, pageIndex),
      newPage,
      ...state.pages.slice(pageIndex + 1),
    ],
  };
}
