import { useState, useEffect, useRef } from '@web-stories-wp/react';
import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { MediaGalleryLoadingPill } from './styles';
import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.fg.secondary};
`;

const SHOW_LOADING_PILL_DELAY_MS = 250;

export function useShowLoadingPill(isLoading) {
  const [showLoadingPill, setShowLoadingPill] = useState(false);
  const hideLoadingPillTimeout = useRef();

  useEffect(() => {
    if (isLoading) {
      if (hideLoadingPillTimeout.current) {
        clearTimeout(hideLoadingPillTimeout.current);
        hideLoadingPillTimeout.current = null;
      }
      setShowLoadingPill(true);
    } else {
      hideLoadingPillTimeout.current = setTimeout(() => {
        setShowLoadingPill(false);
      }, SHOW_LOADING_PILL_DELAY_MS);
    }

    return () => {
      if (hideLoadingPillTimeout.current) {
        clearTimeout(hideLoadingPillTimeout.current);
      }
    };
  }, [isLoading]);

  return showLoadingPill;
}

export default function LoadingPill() {
  return (
    <MediaGalleryLoadingPill>
      <StyledText
        forwardedAs="span"
        size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}
      >
        {__('Loading…', 'web-stories')}
      </StyledText>
    </MediaGalleryLoadingPill>
  );
}
