/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useEffect, useMemo, useRef, useState } from '@web-stories-wp/react';
import styled from 'styled-components';
import { __, sprintf } from '@web-stories-wp/i18n';

/**
 * Internal dependencies
 */
import { CarouselScrollForward, CarouselScrollBack } from './carouselScroll';
import PrimaryMenu from './primaryMenu';
import SecondaryMenu from './secondaryMenu';
import CarouselList from './carouselList';
import useCarousel from './useCarousel';
import { MENU_GUTTER, BUTTON_WIDTH, BUTTON_GAP } from './constants';
import { LoadingBar } from '@src/web-stories-wp/design-system/src';
import { useLocalMedia } from '../../app';

const Wrapper = styled.section`
  position: relative;
  display: grid;
  grid:
    /* Note the two empty 1fr areas each side of the buttons - that's on purpose */
    'secondary . prev-navigation . carousel . next-navigation . primary' auto /
    ${MENU_GUTTER}px
    1fr
    ${BUTTON_WIDTH}px
    ${BUTTON_GAP}px
    auto
    ${BUTTON_GAP}px
    ${BUTTON_WIDTH}px
    1fr
    ${MENU_GUTTER}px;
  width: 100%;
  height: 100%;
`;

const Area = styled.div`
  grid-area: ${({ area }) => area};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function CarouselLayout() {
  const [shouldShowLoadingBar, setShouldShowLoadingBar] = useState(false);
  const { numPages } = useCarousel(({ state: { numPages } }) => ({ numPages }));
  const { progressForAllItems } = useLocalMedia((state) => ({
    progressForAllItems: state.state.progressForAllItems,
  }));

  const hideLoadingBarTimeoutId = useRef(null);

  const itemsAreLoading = useMemo(
    () => progressForAllItems > 0 && progressForAllItems < 100,
    [progressForAllItems]
  );

  useEffect(() => {
    if (itemsAreLoading) {
      setShouldShowLoadingBar(true);
      hideLoadingBarTimeoutId.current = null;
    } else {
      // Don't immediately hide the loading bar
      hideLoadingBarTimeoutId.current = setTimeout(() => {
        setShouldShowLoadingBar(false);
        hideLoadingBarTimeoutId.current = null;
      }, 1000);
    }
  }, [itemsAreLoading]);

  if (numPages <= 0) {
    return null;
  }

  return (
    <Wrapper aria-label={__('Page Carousel', 'web-stories')}>
      <Area area="prev-navigation">
        <CarouselScrollBack />
      </Area>
      <Area area="carousel">
        <CarouselList />
      </Area>
      <Area area="next-navigation">
        <CarouselScrollForward />
      </Area>
      <Area area="primary">
        <PrimaryMenu />
      </Area>
      <Area area="secondary">
        <SecondaryMenu />
      </Area>
      {shouldShowLoadingBar && (
        <LoadingBar
          loadingMessage={sprintf(
            __('Media %s loaded', 'web-stories'),
            progressForAllItems
          )}
          progress={progressForAllItems}
        />
      )}
    </Wrapper>
  );
}

export default CarouselLayout;
