/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
} from '@web-stories-wp/design-system';
import { defaultTypographyStyle } from '@web-stories-wp/design-system/src/components/typography/styles';
import errorImg from './error.svg';

const Message = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 20px;
  button {
    margin-right: 6px;
  }
`;

const P = styled.p`
  ${defaultTypographyStyle}
  font-size: 16px;
  line-height: normal;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RetryButton = styled(Button)`
  line-height: 1.2;
  padding: 8px 20px;
`;

function ErrorActions() {
  const reload = () => {
    window.location.reload(true);
  };

  return (
    <Message>
      <img alt="" src={errorImg} />
      <P fontWeight={700}>{__('Something went wrong!', 'web-stories')}</P>
      <Wrapper>
        <P>
          {__(
            'The editor has crashed. Please try reloading the page.',
            'web-stories'
          )}
        </P>
        <P>{__('Apologies for the inconvenience.', 'web-stories')}</P>
      </Wrapper>
      <Wrapper>
        <RetryButton
          variant={BUTTON_VARIANTS.RECTANGLE}
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.SMALL}
          onClick={reload}
        >
          {__('Retry', 'web-stories')}
        </RetryButton>
      </Wrapper>
    </Message>
  );
}

ErrorActions.propTypes = {
  error: PropTypes.instanceOf(Error),
  errorInfo: PropTypes.object,
};

export default ErrorActions;
