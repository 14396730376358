/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import { __ } from '@web-stories-wp/i18n';
import styled from 'styled-components';
import { Checkbox, Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import {
  FIELD_TYPES,
  getAnimationEffectProps,
} from '@web-stories-wp/animation';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const CheckboxLabel = styled(Text).attrs({
  forwardedAs: 'label',
  size: THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL,
})`
  margin: 0 12px;
`;

function CheckboxInput({ animation, handlePanelChange, selectedElement }) {
  const { props } = getAnimationEffectProps(animation.type);

  const fields = Object.keys(props).map((id) => ({ id, ...props[id] }));

  const checkboxFields = fields.filter(
    (field) => field.type === FIELD_TYPES.CHECKBOX
  );

  return (
    <>
      {checkboxFields.map((field) => {
        if (!field.supportedElementTypes.includes(selectedElement.type)) {
          return null;
        }

        const isFieldChecked = animation[field.id] ? true : false;

        return (
          <CheckboxContainer key={field.id}>
            <Checkbox
              id={field.id}
              checked={isFieldChecked}
              name={field.id}
              onChange={() => {
                handlePanelChange(
                  {
                    ...animation,
                    [field.id]: !isFieldChecked,
                  },
                  true
                );
              }}
            />
            <CheckboxLabel htmlFor={field.id}>{field.label}</CheckboxLabel>
          </CheckboxContainer>
        );
      })}
    </>
  );
}

export default CheckboxInput;
