/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { v4 as uuidv4 } from 'uuid';

/**
 * Internal dependencies
 */
import { DIRECTION } from '../../constants';
import { AnimationMove } from '../../parts/move';
import { AnimationFade } from '../../parts/fade';
import { getOffPageOffset, mergeCssAnimations } from '../../utils';

export function EffectFlyOut({
  duration = 600,
  flyOutDir = DIRECTION.TOP_TO_BOTTOM,
  delay,
  easing = 'cubic-bezier(0.2, 0.6, 0.0, 1)',
  element,
  overflowHidden = false,
}) {
  const id = uuidv4();
  const { offsetTop, offsetLeft, offsetRight, offsetBottom } =
    getOffPageOffset(element);

  const offsetLookup = {
    [DIRECTION.TOP_TO_BOTTOM]: {
      offsetY: `${offsetBottom}%`,
    },
    [DIRECTION.BOTTOM_TO_TOP]: {
      offsetY: `${offsetTop}%`,
    },
    [DIRECTION.LEFT_TO_RIGHT]: {
      offsetX: `${offsetRight}%`,
    },
    [DIRECTION.RIGHT_TO_LEFT]: {
      offsetX: `${offsetLeft}%`,
    },
  };

  const {
    cssAnimation: FadeCssAnimation,
    WAAPIAnimation: FadeWAAPIAnimation,
    AMPTarget: FadeAMPTarget,
    AMPAnimation: FadeAMPAnimation,
    generatedKeyframes: fadeKeyframes,
  } = AnimationFade({
    fadeFrom: 1,
    fadeTo: 0,
    duration: duration,
    delay,
    easing,
  });

  const {
    cssAnimation: MoveCssAnimation,
    elementId: MoveElementId,
    WAAPIAnimation: MoveWAAPIAnimation,
    AMPTarget: MoveAMPTarget,
    AMPAnimation: MoveAMPAnimation,
    generatedKeyframes: moveKeyframes,
  } = AnimationMove({
    ...offsetLookup[flyOutDir],
    animateOut: true,
    duration,
    delay,
    easing,
    element,
    overflowHidden,
  });

  return {
    id,
    cssAnimation: mergeCssAnimations([FadeCssAnimation, MoveCssAnimation]),
    elementId: MoveElementId, // Same as the element used for AMPTarget
    // eslint-disable-next-line react/prop-types
    WAAPIAnimation: function WAAPIAnimation({ children, hoistAnimation }) {
      return (
        <FadeWAAPIAnimation hoistAnimation={hoistAnimation}>
          <MoveWAAPIAnimation hoistAnimation={hoistAnimation}>
            {children}
          </MoveWAAPIAnimation>
        </FadeWAAPIAnimation>
      );
    },
    // eslint-disable-next-line react/prop-types
    AMPTarget: function AMPTarget({
      children,
      cssAnimationStyle,
      elementIdOverride,
      style,
    }) {
      if (cssAnimationStyle) {
        // If the styles are rendered using CSS, we only need one animation wrapper
        // Note that it needs to be one that accepts the overflowHidden prop (if there is one)
        return (
          <MoveAMPTarget
            cssAnimationStyle={cssAnimationStyle}
            elementIdOverride={elementIdOverride}
            style={style}
          >
            {children}
          </MoveAMPTarget>
        );
      }
      return (
        <FadeAMPTarget style={style}>
          <MoveAMPTarget style={style}>{children}</MoveAMPTarget>
        </FadeAMPTarget>
      );
    },
    AMPAnimation: function AMPAnimation() {
      return (
        <>
          <FadeAMPAnimation />
          <MoveAMPAnimation />
        </>
      );
    },
    generatedKeyframes: {
      ...fadeKeyframes,
      ...moveKeyframes,
    },
  };
}
