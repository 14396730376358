import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import VideoProps from '../propsTypes/video';
import { useCallback, useRef } from 'react';
import LoadingPill, { useShowLoadingPill } from '../../../common/loadingPill';
import MediaError from '../../common/mediaError';
import ExternalMediaGalleryElement from '../../common/externalMediaGalleryElement';

const NoMediaFound = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const ExternalMediaGalleryContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 2px;
  padding: 0px 0px 20px 12px;
`;

function ExternalMediaGallery({
  assets,
  isLoading,
  loadAssets,
  hasMore,
  hasError,
  enableDeletion,
  enableEditInAzzuu,
}) {
  const showLoadingPill = useShowLoadingPill(isLoading);
  const observer = useRef();
  const listContainerRef = useRef();
  const lastMediaElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadAssets({ isInitLoad: false });
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, loadAssets]
  );

  return (
    <ExternalMediaGalleryContainer ref={listContainerRef}>
      {hasError && (
        <MediaError
          onRetry={() => loadAssets({ isInitLoad: assets.length === 0 })}
        />
      )}

      {!hasError && (
        <>
          {(!assets || assets.length == 0) && !isLoading ? (
            <NoMediaFound>No media found.</NoMediaFound>
          ) : (
            <ExternalMediaGalleryElement
              providerType=""
              canEditMedia={false}
              resources={assets}
              lastMediaElementRef={lastMediaElementRef}
              enableDeletion={enableDeletion}
              enableEditInAzzuu={enableEditInAzzuu}
            />
          )}
        </>
      )}

      {showLoadingPill && <LoadingPill />}
    </ExternalMediaGalleryContainer>
  );
}

ExternalMediaGallery.propTypes = {
  video: VideoProps,
};

export default ExternalMediaGallery;
