import { useCallback } from 'react';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
  Icons,
  Tooltip,
  useSnackbar,
} from '@web-stories-wp/design-system';
import { StudioButton } from './studioButton';
import { useStory } from '@web-stories-wp/story-editor';
import { PAGE_WIDTH } from '@web-stories-wp/units';

const ariaLabel = 'Copy JSON Page definition to clipboard.';

export function CopyPageToClipboardButton({ disabled }) {
  const { showSnackbar } = useSnackbar();
  const { currentPage } = useStory(({ state }) => ({
    currentPage: state.currentPage,
  }));

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(JSON.stringify({ ...currentPage, canvasWidth: PAGE_WIDTH }))
      .then(
        () => {
          showSnackbar({
            message: 'Page definition copied to clipboard.',
            dismissable: true,
          });
        },
        () => {
          showSnackbar({
            message: 'Page definition copy to clipboard failed!',
            dismissable: true,
          });
        }
      );
  }, [currentPage, showSnackbar]);

  return (
    <Tooltip title={ariaLabel} hasTail>
      <StudioButton
        variant={BUTTON_VARIANTS.SQUARE}
        type={BUTTON_TYPES.SECONDARY}
        size={BUTTON_SIZES.SMALL}
        onClick={copyToClipboard}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        <Icons.PageSwap />
      </StudioButton>
    </Tooltip>
  );
}
