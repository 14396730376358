const TYPES_WITH_STRICT_COMPARISON = [
  'string',
  'number',
  'boolean',
  'bigint',
  'undefined',
];

export function isEqual(
  oldValue: unknown,
  newValue: unknown,
  keysToIgnore: string[] = []
) {
  const newValueType = typeof newValue;
  const oldValueType = typeof oldValue;

  if (newValueType !== oldValueType) {
    return false;
  }

  if (TYPES_WITH_STRICT_COMPARISON.includes(newValueType)) {
    return oldValue === newValue;
  }

  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    const newValueArrayType = typeof newValue[0];
    const oldValueArrayType = typeof oldValue[0];

    if (
      oldValueArrayType !== newValueArrayType ||
      oldValue.length !== newValue.length
    ) {
      return false;
    }

    let areArrayValuesTheSame = true;

    for (
      let newValueIndex = 0;
      newValueIndex < newValue.length;
      newValueIndex++
    ) {
      const newValueToCompare = newValue[newValueIndex];
      const oldValueToCompare = oldValue[newValueIndex];

      if (!isEqual(oldValueToCompare, newValueToCompare, keysToIgnore)) {
        areArrayValuesTheSame = false;
        break;
      }
    }

    return areArrayValuesTheSame;
  }

  if (oldValue === null && newValue === null) {
    return true;
  }

  if (oldValue === null || newValue === null) {
    return false;
  }

  if (typeof oldValue === 'object' && typeof newValue === 'object') {
    const newValueKeys = Object.keys(newValue).filter(
      (key) => !keysToIgnore.includes(key)
    );
    const oldValueKeys = Object.keys(oldValue).filter(
      (key) => !keysToIgnore.includes(key)
    );

    if (newValueKeys.length !== oldValueKeys.length) {
      return false;
    }

    let areNestedValuesTheSame = true;

    for (
      let newValueKeyIndex = 0;
      newValueKeyIndex < newValueKeys.length;
      newValueKeyIndex++
    ) {
      const key = newValueKeys[newValueKeyIndex];
      const newValueToCompare = newValue[key];
      const oldValueToCompare = oldValue[key];

      if (!isEqual(oldValueToCompare, newValueToCompare, keysToIgnore)) {
        areNestedValuesTheSame = false;
        break;
      }
    }

    return areNestedValuesTheSame;
  }

  if (
    // Empty arrays are considered the same as undefined
    // (for example, cssAnimations is undefined for older pages, but if it's empty
    // after editing, the value didn't change)
    (Array.isArray(newValue) &&
      newValue.length === 0 &&
      typeof oldValue === 'undefined') ||
    (Array.isArray(oldValue) &&
      oldValue.length === 0 &&
      typeof newValue === 'undefined')
  ) {
    return false;
  }

  return false;
}
