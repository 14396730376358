/* eslint-disable import/no-duplicates, import/order, no-unused-vars */
import { useMemo } from 'react';
import mockApiProvider from './mockApiProvider';
import getInitStory from './getStoryResponse';
import { fileToBase64 } from '@src/utils/upload/fileToBase64';
import { PAGE_WIDTH } from '@web-stories-wp/units';
import {
  getImageDimensions,
  getVideoDimensions,
  getVideoLength,
} from '@web-stories-wp/media';
import useCmsApi from './useCmsApi';
import { useAuthorizationManager } from '@hooks/authorization';
import { v4 as uuidv4 } from 'uuid';
import { AssetTypes } from '../studioContext/CMSParams';
import { trackAppInsightsError } from '../../initAppInsights';
import { wasPageChanged } from '../../utils/wasPageChanged';
import { offsetPageWithSafeZone } from '../../utils/offsetPageWithSafeZone';

//For fonts, shapes, sticker we don't need paging so bascially we just get all items
const MAX_RESULT_COUNT = 1000;

async function getDataFromList(getList, assetType) {
  let params = { maxResultCount: MAX_RESULT_COUNT };

  if (assetType) {
    params = {
      ...params,
      assetType: assetType,
    };
  }

  const {
    payload: { items },
  } = await getList(params);

  if (!items?.length) {
    return [];
  }

  return items;
}

function createApiProvider({ authContext, studioContext, cmsApi }) {
  if (!studioContext || !cmsApi) return null;

  const {
    assetType,
    contentBankAssetType,
    resourceId,
    studioDefinitionId,
    storyId,
    studioStoryId,
  } = studioContext;
  const {
    studioStoryApi,
    studioStoryThumbnailApi,
    studioStoryIconApi,
    studioContentBankApi,
    templateApi,
    mediaApi,
    uploadsApi,
    mediaProviderApi,
    mediaSourceApi,
    externalAssetsApi,
    fontsApi,
    shapesApi,
    stickersApi,
    textSetsApi,
    studioClipApi,
    studioClipThumbnailApi,
    studioAdApi,
  } = cmsApi;

  const createDefaultStory = () => ({
    ...getInitStory,
  });

  const saveStoryById = (story) => {
    return Promise.resolve({
      status: 'draft',
      slug: '',
      link: '',
      preview_link: {},
      edit_link: {},
      embed_post_link: {},
      featured_media: {},
    });
  };

  const getPageDuration = async (currentPage) => {
    let pageDuration = 0;
    let isVideoPage = false;

    for (let elem of currentPage.elements) {
      if (elem.type === 'video') {
        isVideoPage = true;
        var { originalLength: length } = await getVideoLength(
          elem.resource.src
        );

        if (length > pageDuration) {
          pageDuration = length;
        }
      }
    }

    if (!isVideoPage && currentPage.cssAnimations?.length > 0) {
      const animationsDuration = currentPage.cssAnimations.reduce(
        (maxAnimationDuration, cssAnimation) => {
          if (isNaN(cssAnimation.animationDuration)) {
            return maxAnimationDuration;
          }

          const animationDuration =
            cssAnimation.animationDuration + cssAnimation.animationDelay;

          return animationDuration > maxAnimationDuration
            ? animationDuration
            : maxAnimationDuration;
        },
        0
      );

      return animationsDuration;
    }

    return pageDuration;
  };

  const preparePagesToSave = async (pages) => {
    const result = [];
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      let duration = 0;
      if (page.isChanged) {
        duration = await getPageDuration(page);
      }

      let cmsPageType = page.cmsPageType;

      if (!cmsPageType) {
        const isVideoPage = page.elements.some((elem) => elem.type === 'video');
        cmsPageType = isVideoPage ? 1 : 0;
      }

      result.push({
        duration,
        ampId: page.id,
        animations: page.animations || [],
        cssAnimations: page.cssAnimations || [],
        cmsPageId: page.cmsPageId,
        cmsPageType,
        isChanged: page.isChanged,
        isProcessing: page.isProcessing,
        sortOrder: i,
      });
    }

    return result;
  };

  const getStudioStoryDefinition = () => {
    if (assetType === AssetTypes.Story && storyId) {
      return studioStoryApi.get(studioStoryId, storyId);
    } else if (assetType === AssetTypes.StoryThumbnail && resourceId) {
      return studioStoryThumbnailApi.get(studioDefinitionId, resourceId);
    } else if (assetType === AssetTypes.StoryIcon && resourceId) {
      return studioStoryIconApi.get(studioDefinitionId, resourceId);
    } else if (assetType === AssetTypes.ContentBankAsset && resourceId) {
      return studioContentBankApi.get(studioDefinitionId, resourceId);
    } else if (assetType === AssetTypes.Clip && resourceId) {
      return studioClipApi.get(studioDefinitionId, resourceId);
    } else if (assetType === AssetTypes.ClipThumbnail && resourceId) {
      return studioClipThumbnailApi.get(studioDefinitionId, resourceId);
    } else if (assetType === AssetTypes.Ad && resourceId) {
      return studioAdApi.get(studioDefinitionId, resourceId);
    }
  };

  const savePages = async ({
    storyToSave,
    story,
    deletedPagesIds,
    addNewPagesAtTheEnd,
  }) => {
    const { pages } = storyToSave;
    const { loadedPages, processingPages } = story;

    for (let page of pages) {
      page.isChanged = false; // isChanged flag is saved with StudioStory definition
      let originalPage = (loadedPages || []).find((x) => x.id === page.id);

      if (
        assetType === AssetTypes.ContentBankAsset ||
        assetType === AssetTypes.Clip ||
        assetType === AssetTypes.ClipThumbnail ||
        assetType === AssetTypes.Ad
      ) {
        page.id = studioDefinitionId;
      }

      const pageWasChanged = wasPageChanged(originalPage, page);

      if (pageWasChanged) {
        page.isChanged = true;
      }
    }

    const resultPages = await preparePagesToSave(pages);

    storyToSave.pages = storyToSave.pages.filter((x) => !x.isProcessing);
    storyToSave.canvasWidth = PAGE_WIDTH;

    const basePayload = {
      definition: JSON.stringify(storyToSave),
      pages: resultPages,
    };

    if (assetType === AssetTypes.Story && storyId) {
      var payload = {
        ...basePayload,
        studioStoryId,
        storyId,
        processingPagesIds: processingPages,
        deletedPagesIds,
        addNewPagesAtTheEnd,
      };
      var result = await studioStoryApi.generatePages(payload).catch((e) => {
        trackAppInsightsError('Studio: Generate Pages Failed', e, payload);
        throw e;
      });
      return result.data;
    }
    if (assetType === AssetTypes.StoryThumbnail && resourceId) {
      await studioStoryThumbnailApi.render({
        ...basePayload,
        studioStoryThumbnailId: studioDefinitionId,
        storyId: resourceId,
      });
    }
    if (assetType === AssetTypes.StoryIcon && resourceId) {
      await studioStoryIconApi.render({
        ...basePayload,
        studioStoryIconId: studioDefinitionId,
        storyId: resourceId,
      });
    }

    if (assetType === AssetTypes.ContentBankAsset && resourceId) {
      await studioContentBankApi.render({
        ...basePayload,
        contentBankAssetId: resourceId,
        contentBankAssetDefinitionId: studioDefinitionId,
      });
    }

    if (assetType === AssetTypes.Clip && resourceId) {
      await studioClipApi.render({
        ...basePayload,
        clipId: resourceId,
        studioClipId: studioDefinitionId,
      });
    }

    if (assetType === AssetTypes.ClipThumbnail && resourceId) {
      await studioClipThumbnailApi.render({
        ...basePayload,
        clipId: resourceId,
        studioClipThumbnailId: studioDefinitionId,
      });
    }

    if (assetType === AssetTypes.Ad && resourceId) {
      await studioAdApi.render({
        ...basePayload,
        interstitialAdId: resourceId,
        studioAdId: studioDefinitionId,
      });
    }

    return { storyChangedByOtherUser: false };
  };

  const getStoryById = () => {
    return getStudioStoryDefinition().then((res) => {
      const studioPage = res.payload;
      const { pagesToImport, pagesOrder } = studioPage;

      if (!studioPage.definition) {
        return {
          ...createDefaultStory(),
          pagesToImport,
          pagesOrder,
          title: { raw: studioPage.title },
        };
      }

      const storyData = JSON.parse(studioPage.definition);
      const { slug, date, modified, featuredMedia } = storyData;

      const pages =
        storyData.pages?.map((page) => {
          const pageOrder = pagesOrder.find(
            (x) =>
              page.cmsPageId === x.id ||
              (x.studioPageId != null && x.studioPageId === page.id)
          );

          const engagementUnitProps = pageOrder
            ? { engagementUnitTitle: pageOrder.engagementUnitTitle }
            : {};

          return {
            ...offsetPageWithSafeZone(page),
            ...engagementUnitProps,
          };
        }) || [];

      return {
        ...getInitStory,
        story_data: { pages, version: 35 },
        slug,
        date,
        modified,
        featured_media: featuredMedia,
        title: { raw: studioPage.title },
        excerpt: { raw: '' },
        pagesToImport,
        pagesOrder,
      };
    });
  };

  const addPageTemplate = (template) => {
    const { storyMarkup, ...templateToSave } = template;

    return templateApi
      .save({
        definition: JSON.stringify(templateToSave),
        ampPageDefinition: storyMarkup,
      })
      .then(({ payload: templateId }) => ({
        ...templateToSave,
        templateId,
      }));
  };

  const getCustomPageTemplates = (
    nextTemplatesToFetch,
    pageSize = 16,
    folderName = '',
    searchText = '',
    signal,
    assetType
  ) => {
    return templateApi
      .getList(
        nextTemplatesToFetch,
        16,
        folderName,
        searchText,
        signal,
        assetType
      )
      .then(({ payload: resultPage }) => {
        const templates = resultPage.list.map((template) => ({
          ...JSON.parse(template.definition),
          templateId: template.id,
          posterUrl: template.posterUrl,
          title: template.title,
        }));

        return { templates, hasMore: resultPage.hasMore };
      });
  };

  /**
   * @param {number} pageNumber - page number to fetch
   * @param {number} pageSize
   * @param {string} searchText
   * @returns {*}
   */
  const getTemplatesFolders = (pageNumber, pageSize = 16, searchText = '') => {
    return templateApi
      .getFolders(
        pageNumber,
        pageSize,
        searchText,
        contentBankAssetType || assetType
      )
      .then(({ payload: folders }) => {
        return folders;
      });
  };

  const deletePageTemplate = (templateId) => templateApi._delete(templateId);

  const uploadPoster = async (file, metadata, updateProgress) => {
    const base64File = await fileToBase64(file);
    const uploadedAssetUrl = await uploadsApi.uploadBase64ToAzure(
      base64File,
      { name: 'poster', size: file.size, type: 'image' },
      updateProgress
    );

    if (!uploadedAssetUrl) {
      throw new Error('Asset upload failed. Uploaded asset URL is empty.');
    }

    let assetSize = await getImageDimensions(uploadedAssetUrl);
    const poster = {
      src: uploadedAssetUrl,
      width: assetSize.width,
      height: assetSize.height,
      mimeType: file.type,
      mediaId: metadata.post,
    };

    return { ...poster, includes: () => false };
  };

  const uploadMedia = async (file, metadata, _media, updateProgress) => {
    if (metadata.media_source === 'poster-generation') {
      return await uploadPoster(file, metadata, updateProgress);
    }

    const isVideo = file.type.includes('video');
    const fileType = isVideo ? 'video' : 'image';
    const base64File = await fileToBase64(file);
    const uploadedAssetUrl = await uploadsApi.uploadBase64ToAzure(
      base64File,
      { name: 'original', size: file.size, type: fileType },
      updateProgress
    );

    if (!uploadedAssetUrl) {
      throw new Error('Asset upload failed. Uploaded asset URL is empty.');
    }

    let assetSize = {};

    if (isVideo) {
      assetSize = await getVideoDimensions(uploadedAssetUrl);
    } else {
      assetSize = await getImageDimensions(uploadedAssetUrl);
    }

    const media = {
      alt: metadata.alt_text,
      src: uploadedAssetUrl,
      width: assetSize.width,
      height: assetSize.height,
      title: file.name,
      type: fileType,
      mimeType: file.type,
      sizes: [],
      local: true,
      isTranscoding: false,
      isMuting: false,
      isTrimming: false,
      id: uuidv4(),
    };

    return { ...media };
  };

  // eslint-disable-next-line no-underscore-dangle
  const deleteMedia = (mediaId) => mediaApi._delete(mediaId);
  const deleteExternalAsset = (mediaId) => mediaSourceApi._delete(mediaId);

  const getMediaFromProvider = async (mediaProviderId, params) => {
    const { payload } = await mediaProviderApi.getMedias(
      mediaProviderId,
      params
    );
    const { media, nextPageToken } = payload;

    return {
      media,
      nextPageToken,
    };
  };

  const { registerUsage, download } = mediaProviderApi;

  const getExternalAssets = async (params, signal) => {
    const { payload } = await externalAssetsApi.getExternalAssets(
      params,
      signal
    );

    return payload;
  };

  const getFonts = async () => {
    const items = await getDataFromList(fontsApi.getList);

    return items.map(({ fontMetrics }) => JSON.parse(fontMetrics));
  };

  const getShapes = async () => {
    const items = await getDataFromList(shapesApi.getList);

    return items;
  };

  const getStickers = async () => {
    const items = await getDataFromList(stickersApi.getList);

    return items;
  };

  const getTextSets = async () => {
    const items = await getDataFromList(
      textSetsApi.getList,
      contentBankAssetType || assetType
    );

    return items.map(({ definition, coverImageUrl }) => ({
      ...JSON.parse(definition),
      coverImageUrl,
      isCustom: true,
    }));
  };

  return {
    ...mockApiProvider,
    saveStoryById,
    autoSaveById: saveStoryById,
    getStoryById,
    addPageTemplate,
    getCustomPageTemplates,
    getTemplatesFolders,
    deletePageTemplate,
    uploadMedia,
    deleteMedia,
    deleteExternalAsset,
    savePages,
    studioStoryId,
    storyId,
    getMediaFromProvider,
    registerUsage,
    download3pAsset: download,
    getExternalAssets,
    getFonts,
    getShapes,
    getStickers,
    getTextSets,
  };
}

const useStudioApiProvider = ({ studioContext, story }) => {
  const cmsApi = useCmsApi();
  const { authContext } = useAuthorizationManager();

  const provider = useMemo(
    () => createApiProvider({ authContext, studioContext, story, cmsApi }),
    [authContext, studioContext, story, cmsApi]
  );

  return provider;
};

export default useStudioApiProvider;

/* eslint-enable import/no-duplicates, import/order, no-unused-vars */
